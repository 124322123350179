export default function ({ $axios, route, error }) {
	// Priprava prace s tokenem v parametru URL
	if (route.query.token) {
		const token = route.query.token;

		return $axios.$post('/reset-password/validate-token',
			{
				token: token,
			})
			.catch(() => {
				error({ statusCode: 422, message: 'Token not exist, or expired, or was applied.' });
			});
	}
}
