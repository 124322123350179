import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a85e0ebc&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Nav: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/Nav.vue').default,InfoFooter: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/InfoFooter.vue').default,PopupQuestions: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/PopupQuestions.vue').default,PopupQuestionsIqosScience: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/PopupQuestionsIqosScience.vue').default,PopupQuestionsIqosThird: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/PopupQuestionsIqosThird.vue').default,ModalWindow: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/ModalWindow.vue').default})
