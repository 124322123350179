//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
	data () {
		return {
			checkboxNews: false,
			showPopupNewsletter: false,

			disabledSendBtn: false,

			// descriptionPopupContent: true,
			// questionsPopupContent: false,
			// returnPopupContent: false,
			// gratulationPopupContent: false,
		};
	},

	async fetch () {
		this.$store.commit('popupQuestions/RESET_MY_POPUP_QUESTION', []);
		this.$store.commit('popupQuestions/RESET_POPUP_QUESTIONS', []);

		this.$axios.$get('/surveys/iqos')
			.then(questionsResponse => {
				this.$store.dispatch('popupQuestions/popupQuestionsSet', questionsResponse.payload.questions);

				questionsResponse.payload.questions.forEach(question => {
					this.$store.dispatch('popupQuestions/addMyPopupQuestion', { questionId: question.id, answerId: null });
				});
			});
	},

	computed: {
		...mapState({
			currentQuestion: (state) => state.popupQuestions.currentQuestion,
			popupQuestions: (state) => state.popupQuestions.popupQuestions,
			popupTypes: (state) => state.popupQuestions.popupTypes,
			extraCodes: (state) => state.popupQuestions.extraCodes,
			myPopupQuestions: (state) => state.popupQuestions.myPopupQuestions,
			rules: (state) => state.conditions.rules,
		}),
	},

	async mounted () {
		this.$axios.get('/registration/terms-and-conditions')
			.then((responseTermsAndConditions) => {
				if (responseTermsAndConditions.data.status === 'ok') {
					this.$store.commit('conditions/TERMS_AND_CONDITIONS', responseTermsAndConditions.data.payload);
				}
			});
	},

	methods: {
		closeModal () {
			this.$store.dispatch('userLogin/popupQuestions', false);
			this.updatePopupTypes('gratulationPopupContent', false);
			this.updatePopupTypes('returnPopupContent', false);
			this.updatePopupTypes('descriptionPopupContent', true);
		},

		updatePopupTypes (fieldName, updatedValue) {
			this.$store.dispatch('popupQuestions/updatePopupTypes', { fieldName, updatedValue });
		},

		answerLater () {
			this.updatePopupTypes('descriptionPopupContent', false);
			this.updatePopupTypes('returnPopupContent', true);

			this.$axios.$post('/surveys/omission', { surveyId: 1 });

			this.$axios.get('/me')
				.then(userMeResponse => {
					this.$auth.setUser(userMeResponse.data.payload);
				})
				.catch((error) => {
					if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
						this.store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
					}
				});
		},

		returnIqosPage () {
			this.updatePopupTypes('gratulationPopupContent', false);
			this.updatePopupTypes('returnPopupContent', false);
			this.updatePopupTypes('descriptionPopupContent', true);
			this.$store.dispatch('userLogin/popupQuestions', false);

			this.$router.push('/iqos');
		},

		sendQuestions () {
			// Zneaktivneni tlacitka po odeslani formulare
			this.disabledSendBtn = true;

			let userSurveyAnswersArr = { userSurveyAnswers: [] };

			this.myPopupQuestions.forEach(answer => {
				userSurveyAnswersArr.userSurveyAnswers.push({ surveyAnswerId: answer.answerId });
			});

			this.$axios.post('/surveys/iqos', { userSurveyAnswers: userSurveyAnswersArr.userSurveyAnswers, iqosConsent: this.checkboxNews })
				.then((responseSurveysIqos) => {
					if (responseSurveysIqos.data.status === 'ok') {
						// Zaktivneni tlacitka po uspesnem odeslani formulare
						this.disabledSendBtn = false;

						this.$store.dispatch('popupQuestions/extraCodesUpdate', responseSurveysIqos.data.payload);

						this.updatePopupTypes('questionsPopupContent', false);
						this.updatePopupTypes('gratulationPopupContent', true);
						this.$store.commit('popupQuestions/RESET_MY_POPUP_QUESTION', []);
						this.$store.commit('popupQuestions/RESET_POPUP_QUESTIONS', []);

						this.$axios.get('/me')
							.then(userMeResponse => {
								this.$auth.setUser(userMeResponse.data.payload);
							})
							.catch((error) => {
								if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
									this.store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
								}
							});
					}
				})
				.catch((error) => {
					// Zaktivneni tlacitka po uspesnem odeslani formulare
					this.disabledSendBtn = false;

					if (error.response.data.code === 422) {
						this.updatePopupTypes('errorPopupContent', true);
						this.updatePopupTypes('questionsPopupContent', false);
						this.updatePopupTypes('gratulationPopupContent', false);
					}
				});
		},

		triggerModal () {
			this.showPopupNewsletter = false;
		},
	},
};
