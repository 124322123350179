export default function ({ $gtm }) {
	if (location.host === 'soutez100cen.cz') {
		$gtm.init('GTM-TXHP69D');
	}

	if (location.host === 'sutaz100cien.sk') {
		$gtm.init('GTM-T8GW85B');
	}

	if (location.host === 'soutez100cen-test.pmitest.cz') {
		$gtm.init('GTM-T4TGNK4');
	}

	if (location.host === 'soutez100cen-test-sk.pmitest.cz') {
		$gtm.init('GTM-KX57T54');
	}
}
