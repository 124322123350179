export default function ({ store, route, redirect, app }) {
	if (store.state.userLogin.ageGate === false && route.path === '/prihlasit') {
		return;
	}
	else if (app.i18n.locale == 'sk' && store.state.userLogin.ageGate === false && route.path === '/pravidla') {
		return;
	}
	else if (store.state.userLogin.ageGate === false && route.path === '/reset-hesla/') {
		return;
	}
	else if (store.state.userLogin.ageGate === false && route.path !== '/') {
		// console.log(store.state.userLogin.ageGate);
		// console.log(route);
		return redirect('/');
	}
	else if (store.state.userLogin.ageGate === true && route.path === '/') {
		return redirect('/soutez');
	}
}
