/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	prizes: [],
});

export const getters = {
	prizes: state => state.prizes,
	prizeById: state => id => state.prizes.find(prize => prize.id === id),
};

export const mutations = {
	setPrizes (state, prizes) {
		state.prizes = prizes;
	},
};

export const actions = {
	getPrizes ({ commit }, date) {
		return this.$axios.get('/winnings', {
			params: {
				date,
			},
		}).then(resp => {
			commit('setPrizes', resp.data.payload);
			return resp;
		});
	},
};
