//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
	data () {
		return {
			disabledSendBtn: false,

			surveyId: null,
			minDuration: null,
			showBtnStartQuiz: false,

			checkboxNews: false,
			showPopupNewsletter: false,

			plyrOptions: {
				controls: [
					'play-large', 'play', 'current-time', 'mute', 'volume',
				],
			},
		};
	},

	async fetch () {
		this.$store.commit('popupQuestions/RESET_MY_POPUP_QUESTION', []);
		this.$store.commit('popupQuestions/RESET_POPUP_QUESTIONS', []);

		this.$axios.$get('/surveys/iqos-science')
			.then(questionsResponse => {
				this.$store.dispatch('popupQuestions/popupQuestionsSet', questionsResponse.payload.questions);

				questionsResponse.payload.questions.forEach(question => {
					this.$store.dispatch('popupQuestions/addMyPopupQuestion', { questionId: question.id, answerId: null });
				});

				this.surveyId = questionsResponse.payload.id;
			});
	},

	computed: {
		...mapState({
			currentQuestion: (state) => state.popupQuestions.currentQuestion,
			popupQuestions: (state) => state.popupQuestions.popupQuestions,
			popupTypes: (state) => state.popupQuestions.popupTypes,
			extraCodes: (state) => state.popupQuestions.extraCodes,
			myPopupQuestions: (state) => state.popupQuestions.myPopupQuestions,
			rules: (state) => state.conditions.rules,
		}),
	},

	async mounted () {
		this.$refs.plyr.player.on('play', () => {
			this.minDuration = this.$refs.plyr.player.duration / 3;
		});

		this.$refs.plyr.player.on('timeupdate', () => {
			if (this.minDuration <= this.$refs.plyr.player.currentTime) {
				this.showBtnStartQuiz = true;
			}
		});



		this.$axios.get('/registration/terms-and-conditions')
			.then((responseTermsAndConditions) => {
				if (responseTermsAndConditions.data.status === 'ok') {
					this.$store.commit('conditions/TERMS_AND_CONDITIONS', responseTermsAndConditions.data.payload);
				}
			});
	},

	methods: {
		closeModal () {
			this.$store.dispatch('userLogin/popupQuestionsIqosScience', false);
			this.updatePopupTypes('videoPopupContent', false);
			this.updatePopupTypes('returnPopupContent', false);
			this.updatePopupTypes('gratulationPopupContent', false);
			this.updatePopupTypes('descriptionPopupContent', true);
			// this.$refs.plyr.player.pause();
		},

		backStep () {
			this.updatePopupTypes('videoPopupContent', false);
			this.updatePopupTypes('descriptionPopupContent', true);
			this.$refs.plyr.player.pause();
		},

		updatePopupTypes (fieldName, updatedValue) {
			this.$store.dispatch('popupQuestions/updatePopupTypes', { fieldName, updatedValue });
		},

		answerLater () {
			this.$store.dispatch('userLogin/popupQuestionsIqosScience', false);
			this.$store.dispatch('userLogin/popupQuestionsIqosScienceLater', true);
		},

		answerNever () {
			this.updatePopupTypes('descriptionPopupContent', false);
			this.updatePopupTypes('videoPopupContent', false);
			this.updatePopupTypes('returnPopupContent', true);
			this.$store.dispatch('userLogin/popupQuestionsIqosScienceLater', true);

			this.$axios.$post('/surveys/omission', { surveyId: this.surveyId });

			this.$axios.get('/me')
				.then(userMeResponse => {
					this.$auth.setUser(userMeResponse.data.payload);
				})
				.catch((error) => {
					if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
						this.store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
					}
				});
		},

		returnIqosPage () {
			this.updatePopupTypes('videoPopupContent', false);
			this.updatePopupTypes('descriptionPopupContent', true);
			this.updatePopupTypes('gratulationPopupContent', false);
			this.$store.dispatch('userLogin/popupQuestionsIqosScience', false);
			this.updatePopupTypes('returnPopupContent', false);

			this.$router.push('/iqos');
		},

		sendQuestions () {
			// Zneaktivneni tlacitka po odeslani formulare
			this.disabledSendBtn = true;

			let userSurveyAnswersArr = { userSurveyAnswers: [] };

			this.myPopupQuestions.forEach(answer => {
				userSurveyAnswersArr.userSurveyAnswers.push({ surveyAnswerId: answer.answerId });
			});

			this.$axios.post('/surveys/iqos-science', { userSurveyAnswers: userSurveyAnswersArr.userSurveyAnswers, iqosConsent: this.checkboxNews })
				.then((responseSurveysIqos) => {
					if (responseSurveysIqos.data.status === 'ok') {
						// Zaktivneni tlacitka po uspesnem odeslani formulare
						this.disabledSendBtn = false;

						this.$store.dispatch('popupQuestions/extraCodesUpdate', responseSurveysIqos.data.payload);

						this.updatePopupTypes('questionsPopupContent', false);
						this.updatePopupTypes('gratulationPopupContent', true);
						this.$store.commit('popupQuestions/RESET_MY_POPUP_QUESTION', []);
						this.$store.commit('popupQuestions/RESET_POPUP_QUESTIONS', []);

						this.$axios.get('/me')
							.then(userMeResponse => {
								this.$auth.setUser(userMeResponse.data.payload);
							})
							.catch((error) => {
								if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
									this.store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
								}
							});
					}
				})
				.catch((error) => {
					// Zaktivneni tlacitka po uspesnem odeslani formulare
					this.disabledSendBtn = false;

					if (error.response.data.code === 422) {
						this.updatePopupTypes('errorPopupContent', true);
						this.updatePopupTypes('questionsPopupContent', false);
						this.updatePopupTypes('gratulationPopupContent', false);
					}
				});
		},

		triggerModal () {
			this.showPopupNewsletter = false;
		},
	},
};
