export default function ({ $axios, $auth, store, redirect }) {
	if ($auth.loggedIn) {
		return $axios.get('/me')
			.then(userMeResponse => {
				$auth.setUser(userMeResponse.data.payload);
				store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'messageLogOut', updatedValue: null });
			})
			.catch((error) => {
				if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
					store.dispatch('userLogin/popupQuestionsIqosScienceLater', false);
					// store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
					store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'messageLogOut', updatedValue: 'Unauthorized' });
					$auth.logout();
					return redirect('/prihlasit');
				}
			});
	}
}
