import Vue from 'vue';
/* eslint no-shadow: ["error", { "allow": ["state"] }]*/
export const state = () => ({
	rules: {},
});

export const mutations = {
	TERMS_AND_CONDITIONS (state, arr) {
		arr.forEach((element) => {
			Vue.set(state.rules, element.type, element.data);
		});
	},
};
