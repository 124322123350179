//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
	props: {
		question: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			showNote: false,
			hasAnswered: false,

			disabledSendBtn: false,
		};
	},

	validations () {
		return {
			myAnswerId: {
				required,
			},
		};
	},

	computed: {
		myAnswerId: {
			get () {
				return this.myPopupQuestions.find(item => item.questionId === this.question.id).answerId;
			},
			set (value) {
				this.$store.commit('popupQuestions/UPDATE_MY_ANSWER_ID', { questionId: this.question.id, answerId: value });
			},
		},

		selectedAnswer () {
			return this.question.answers.find(answer => answer.id === this.myPopupQuestions.find(item => item.questionId === this.question.id).answerId);
		},

		...mapState({
			currentQuestion: (state) => state.popupQuestions.currentQuestion,
			popupQuestions: (state) => state.popupQuestions.popupQuestions,
			myPopupQuestions: (state) => state.popupQuestions.myPopupQuestions,
		}),
	},

	methods: {
		sendQuestions () {
			// Zneaktivneni tlacitka po odeslani formulare
			this.disabledSendBtn = true;

			let userSurveyAnswersArr = { userSurveyAnswers: [] };

			this.myPopupQuestions.forEach(answer => {
				userSurveyAnswersArr.userSurveyAnswers.push({ surveyAnswerId: answer.answerId });
			});

			this.$axios.post('/surveys/iqos', { userSurveyAnswers: userSurveyAnswersArr.userSurveyAnswers, iqosConsent: true })
				.then((responseSurveysIqos) => {
					if (responseSurveysIqos.data.status === 'ok') {
						// Zaktivneni tlacitka po uspesnem odeslani formulare
						this.disabledSendBtn = false;

						this.$store.dispatch('popupQuestions/extraCodesUpdate', responseSurveysIqos.data.payload);

						this.updatePopupTypes('questionsPopupContent', false);
						this.updatePopupTypes('gratulationPopupContent', true);
						this.$store.commit('popupQuestions/RESET_MY_POPUP_QUESTION', []);
						this.$store.commit('popupQuestions/RESET_POPUP_QUESTIONS', []);

						this.$axios.get('/me')
							.then(userMeResponse => {
								this.$auth.setUser(userMeResponse.data.payload);
							})
							.catch((error) => {
								if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
									this.store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
								}
							});
					}
				})
				.catch(() => {
					// Zaktivneni tlacitka po uspesnem odeslani formulare
					this.disabledSendBtn = false;

					// if (error.response.data.code === 422) {
					this.updatePopupTypes('errorPopupContent', true);
					this.updatePopupTypes('questionsPopupContent', false);
					this.updatePopupTypes('gratulationPopupContent', false);
					// }
				});
			// .catch((error) => {
			// if (error.response.data.code === 422 && error.response.data.context.validation.validBirthdate) {
			// 	this.errorsValidBirthdate = error.response.data.context.validation.validBirthdate;
			// }
			// });
		},

		sendQuestionsIqosScience () {
			// Zneaktivneni tlacitka po odeslani formulare
			this.disabledSendBtn = true;

			let userSurveyAnswersArr = { userSurveyAnswers: [] };

			this.myPopupQuestions.forEach(answer => {
				userSurveyAnswersArr.userSurveyAnswers.push({ surveyAnswerId: answer.answerId });
			});

			this.$axios.post('/surveys/iqos-science', { userSurveyAnswers: userSurveyAnswersArr.userSurveyAnswers, iqosConsent: true })
				.then((responseSurveysIqos) => {
					if (responseSurveysIqos.data.status === 'ok') {
						// Zaktivneni tlacitka po uspesnem odeslani formulare
						this.disabledSendBtn = false;

						this.$store.dispatch('popupQuestions/extraCodesUpdate', responseSurveysIqos.data.payload);

						this.updatePopupTypes('questionsPopupContent', false);
						this.updatePopupTypes('gratulationPopupContent', true);
						this.$store.commit('popupQuestions/RESET_MY_POPUP_QUESTION', []);
						this.$store.commit('popupQuestions/RESET_POPUP_QUESTIONS', []);

						this.$axios.get('/me')
							.then(userMeResponse => {
								this.$auth.setUser(userMeResponse.data.payload);
							})
							.catch((error) => {
								if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
									this.store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
								}
							});
					}
				})
				.catch(() => {
					// Zaktivneni tlacitka po uspesnem odeslani formulare
					this.disabledSendBtn = false;

					// if (error.response.data.code === 422) {
					this.updatePopupTypes('errorPopupContent', true);
					this.updatePopupTypes('questionsPopupContent', false);
					this.updatePopupTypes('gratulationPopupContent', false);
					// }
				});
			// .catch((error) => {
			// if (error.response.data.code === 422 && error.response.data.context.validation.validBirthdate) {
			// 	this.errorsValidBirthdate = error.response.data.context.validation.validBirthdate;
			// }
			// });
		},

		sendQuestionsIqosThird () {
			// Zneaktivneni tlacitka po odeslani formulare
			this.disabledSendBtn = true;

			let userSurveyAnswersArr = { userSurveyAnswers: [] };

			this.myPopupQuestions.forEach(answer => {
				userSurveyAnswersArr.userSurveyAnswers.push({ surveyAnswerId: answer.answerId });
			});

			this.$axios.post('/surveys/iqos-third', { userSurveyAnswers: userSurveyAnswersArr.userSurveyAnswers, iqosConsent: true })
				.then((responseSurveysIqos) => {
					if (responseSurveysIqos.data.status === 'ok') {
						// Zaktivneni tlacitka po uspesnem odeslani formulare
						this.disabledSendBtn = false;

						this.$store.dispatch('popupQuestions/extraCodesUpdate', responseSurveysIqos.data.payload);

						this.updatePopupTypes('questionsPopupContent', false);
						this.updatePopupTypes('gratulationPopupContent', true);
						this.$store.commit('popupQuestions/RESET_MY_POPUP_QUESTION', []);
						this.$store.commit('popupQuestions/RESET_POPUP_QUESTIONS', []);

						this.$axios.get('/me')
							.then(userMeResponse => {
								this.$auth.setUser(userMeResponse.data.payload);
							})
							.catch((error) => {
								if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
									this.store.dispatch('userLogin/updateFieldErrorMessages', { fieldName: 'accessToken', updatedValue: error.response.data.context.validation.accessToken });
								}
							});
					}
				})
				.catch(() => {
					// Zaktivneni tlacitka po uspesnem odeslani formulare
					this.disabledSendBtn = false;

					this.updatePopupTypes('errorPopupContent', true);
					this.updatePopupTypes('questionsPopupContent', false);
					this.updatePopupTypes('gratulationPopupContent', false);
				});
		},

		checkAnswer () {
			if (this.$v.$invalid) {
				this.$v.$touch();
			}
			else {
				this.hasAnswered = true;
				this.selectedAnswer;
				this.showNote = true;
			}
		},

		nextQuestions () {
			this.showNote = false;
			this.$store.dispatch('popupQuestions/currentQuestionUpdate', this.currentQuestion + 1);
		},

		updatePopupTypes (fieldName, updatedValue) {
			this.$store.dispatch('popupQuestions/updatePopupTypes', { fieldName, updatedValue });
		},
	},
};
