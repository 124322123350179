import { render, staticRenderFns } from "./PopupQuestions.vue?vue&type=template&id=2fe34f4c&"
import script from "./PopupQuestions.vue?vue&type=script&lang=js&"
export * from "./PopupQuestions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Question: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/Question.vue').default,ModalWindow: require('/builds/2oYM8JSf/4/mcp/soutez-100-cen/frontend/components/ModalWindow.vue').default})
