//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
	name: 'LayoutsDefault',
	middleware: [ 'ageGate', 'authUser' ],

	data () {
		return {
			showMessagesModal: false,
		};
	},

	async fetch () {
		// Zobrazit popup Questions kdyz se prihlasil potreti
		if (this.$auth.loggedIn && this.$auth.user.iqosFormAutostart && !this.timerPopup.show) {
			this.$store.dispatch('userLogin/popupQuestions', true);
		}

		// Zobrazit popup Questions Iqos Science
		if (this.$auth.loggedIn && this.$auth.user.iqosScienceFormAutostart && !this.timerPopup.show && !this.popupQuestionsIqosScienceLater) {
			this.$store.dispatch('userLogin/popupQuestionsIqosScience', true);
		}

		// Zobrazit popup Questions Iqos Third
		if (this.$auth.loggedIn && this.$auth.user.iqosThirdFormAutostart && !this.timerPopup.show && !this.popupQuestionsIqosThirdLater) {
			this.$store.dispatch('userLogin/popupQuestionsIqosThird', true);
		}
	},

	head () {
		return {
			script: [
				{
					skip: this.$config.oneTrustData[this.$i18n.locale] === '',
					src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
					id: 'onetrust',
					type: 'text/javascript',
					'data-domain-script': this.$config.oneTrustData[this.$i18n.locale],
					charset: 'UTF-8',
					// body: true,
				},
			],
		};
	},

	computed: {
		...mapState({
			popupQuestions: (state) => state.userLogin.popupQuestions,
			userMessages: (state) => state.profile.userMessages,
			timerPopup: (state) => state.userLogin.timerPopup,
			popupQuestionsIqosScience: (state) => state.userLogin.popupQuestionsIqosScience,
			popupQuestionsIqosScienceLater: (state) => state.userLogin.popupQuestionsIqosScienceLater,
			popupQuestionsIqosThird: (state) => state.userLogin.popupQuestionsIqosThird,
			popupQuestionsIqosThirdLater: (state) => state.userLogin.popupQuestionsIqosThirdLater,
		}),
	},

	methods: {
		getUserMessages () {
			this.$axios.$get('/me/notifications')
				.then(response => {
					this.$store.commit('profile/UPDATE_MESSAGES', response.payload);
				});
		},

		triggerMessagesModal () {
			this.showMessagesModal = !this.showMessagesModal;
		},
	},
};
