//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	methods: {
		showCookiesWindow () {
			/* eslint-disable-next-line */
			window.OneTrust.ToggleInfoDisplay();
		},
	},
};
