/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	prizesImages: [],
});

export const getters = {
	prizesImagesWithoutMain: state => state.prizesImages.filter(prizeImage => !prizeImage.main),
};

export const mutations = {
	setPrizes (state, prizes) {
		state.prizesImages = prizes;
	},
};

export const actions = {
	getPrizes ({ commit }) {
		return this.$axios.get('/prizes', {
		}).then(resp => {
			commit('setPrizes', resp.data.payload);
			return resp;
		});
	},
};
