/* eslint no-shadow: ["error", { "allow": ["state"] }]*/
import Vue from 'vue';

export const state = () => ({
	insertedCoupons: [],
	myPrizes: [],
	changePassword: {
		currentPassword: null,
		password: null,
		repeatPassword: null,
	},
	errorMessages: {},
	userMessages: [],
});

export const getters = {
	myPrizes: state => state.myPrizes,
	anyPrizesWon: state => state.myPrizes.some(prize => prize.count !== 0),
};

export const mutations = {
	UPDATE_INSERTED_COUPONS (state, coupons) {
		state.insertedCoupons = coupons;
	},

	UPDATE_MESSAGES (state, messages) {
		state.userMessages = messages;
	},

	setMyPrizes (state, prizes) {
		state.myPrizes = prizes;
	},

	UPDATE_FIELD_PASSWORD (state, payload) {
		Vue.set(state.changePassword, payload.fieldName, payload.updatedValue);
	},

	RESET_PASSWORD_CHANGE (state) {
		for (const value in state.changePassword) {
			if (state.changePassword.hasOwnProperty(value)) {
				state.changePassword[value] = null;
			}
		}
	},
};

export const actions = {
	getMyPrizes ({ commit }) {
		return this.$axios.get('/me/winnings').then(resp => {
			commit('setMyPrizes', resp.data.payload);
			return resp;
		});
	},

	updateFieldPassword ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD_PASSWORD', { fieldName, updatedValue });
	},
};
