//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data () {
		return {
			showMobileMenu: false,
		};
	},

	methods: {
		logOut () {
			this.$store.dispatch('userLogin/popupQuestionsIqosScienceLater', false);
			this.$auth.logout();
			this.$router.push(this.localePath('/prihlasit'));
		},

		showMessagesModal () {
			this.$emit('showMessagesModal');

			this.$axios.post('/me/read-notifications', {});

			this.$axios.get('/me')
				.then(userMeResponse => {
					this.$auth.setUser(userMeResponse.data.payload);
				});
		},
	},
};
