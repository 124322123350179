import Vue from 'vue';
/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	popupQuestions: [],
	myPopupQuestions: [],
	popupTypes: {
		descriptionPopupContent: true,
		questionsPopupContent: false,
		returnPopupContent: false,
		gratulationPopupContent: false,
		errorPopupContent: false,
	},
	currentQuestion: 1,
	extraCodes: [],
});

export const mutations = {
	POPUP_QUESTIONS (state, value) {
		state.popupQuestions = value;
	},

	RESET_POPUP_QUESTIONS (state, value) {
		state.popupQuestions = value;
	},

	RESET_MY_POPUP_QUESTION (state, value) {
		state.myPopupQuestions = value;
	},

	ADD_MY_POPUP_QUESTION (state, question) {
		state.myPopupQuestions.push(question);
	},

	UPDATE_MY_ANSWER_ID (state, questionObj) {
		state.myPopupQuestions.find(item => item.questionId === questionObj.questionId).answerId = questionObj.answerId;
	},

	UPDATE_POPUP_TYPES (state, popupTypesData) {
		Vue.set(state.popupTypes, popupTypesData.fieldName, popupTypesData.updatedValue);
	},

	CURRENT_QUESTION (state, value) {
		state.currentQuestion = value;
	},

	EXTRA_CODES (state, value) {
		state.extraCodes = value;
	},
};

export const actions = {
	popupQuestionsSet ({ commit }, payload) {
		commit('POPUP_QUESTIONS', payload);
	},

	addMyPopupQuestion ({ commit }, payload) {
		commit('ADD_MY_POPUP_QUESTION', payload);
	},

	updatePopupTypes ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_POPUP_TYPES', { fieldName, updatedValue });
	},

	currentQuestionUpdate ({ commit }, payload) {
		commit('CURRENT_QUESTION', payload);
	},

	extraCodesUpdate ({ commit }, payload) {
		commit('EXTRA_CODES', payload);
	},
};
