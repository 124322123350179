import Vue from 'vue';
/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	ageGate: false,
	timerPopup: {
		show: false,
		days: true,
	},
	popupQuestions: false,
	popupQuestionsProfil: false,
	popupQuestionsIqosScience: false,
	popupQuestionsIqosScienceLater: false,
	popupQuestionsIqosThird: false,
	popupQuestionsIqosThirdLater: false,

	userRegistration: {
		currentPage: '1',
		rulesAgree: null,
		gdprAgree: null,

		cigaretteCode: null,
		cigaretteCodePayload: null,
		cigaretteBrandId: null,
		username: null,
		password: null,
		repeatPassword: null,
		firstname: null,
		lastname: null,
		birthDay: null,
		birthMonth: null,
		birthYear: null,
		phoneCode: null,
		phone: null,
		email: null,
		repeatEmail: null,
		recaptchaToken: null,
		userToken: null,

		street: null,
		streetNumber: null,
		city: null,
		zip: null,
		postal: false,
		postalStreet: null,
		postalStreetNumber: null,
		postalCity: null,
		postalZip: null,

		questionId: null,
		answer: null,
		brandId: null,
		newsletterAgree: false,

		retailerCode: null,
	},

	errorMessages: {
		requestedUserValidReservation: [],
		validPhoneNumber: [],
		accessToken: [],
		messageLogOut: null,
	},

	selectBoxes: {
		phoneCodes: null,
		questions: null,
		brands: null,
		brandsPmi: null,
	},
});

export const mutations = {
	AGE_GATE (state, value) {
		state.ageGate = value;
	},

	TIMER_POPUP (state, popupData) {
		Vue.set(state.timerPopup, popupData.fieldName, popupData.updatedValue);
	},

	POPUP_QUESTIONS (state, value) {
		state.popupQuestions = value;
	},

	POPUP_QUESTIONS_IQOS_SCIENCE (state, value) {
		state.popupQuestionsIqosScience = value;
	},

	POPUP_QUESTIONS_IQOS_SCIENCE_LATER (state, value) {
		state.popupQuestionsIqosScienceLater = value;
	},

	POPUP_QUESTIONS_IQOS_THIRD (state, value) {
		state.popupQuestionsIqosThird = value;
	},

	POPUP_QUESTIONS_IQOS_THIRD_LATER (state, value) {
		state.popupQuestionsIqosThirdLater = value;
	},

	POPUP_QUESTIONS_PROFIL (state, value) {
		state.popupQuestionsProfil = value;
	},

	// Po prihlaseni vymaž user registration data
	RESET_USER_REGISTRATION_DATA (state) {
		let defaultRegistrationData = {
			currentPage: '1',
			rulesAgree: null,
			gdprAgree: null,

			cigaretteCode: null,
			cigaretteCodePayload: null,
			cigaretteBrandId: null,
			username: null,
			password: null,
			repeatPassword: null,
			firstname: null,
			lastname: null,
			birthDay: null,
			birthMonth: null,
			birthYear: null,
			phoneCode: null,
			phone: null,
			email: null,
			repeatEmail: null,
			recaptchaToken: null,
			userToken: null,

			street: null,
			streetNumber: null,
			city: null,
			zip: null,
			postal: false,
			postalStreet: null,
			postalStreetNumber: null,
			postalCity: null,
			postalZip: null,

			questionId: null,
			answer: null,
			brandId: null,
			newsletterAgree: false,

			retailerCode: null,
		};
		state.userRegistration = defaultRegistrationData;
	},

	// UPDATE FIELDS -----------------------------------------------------------------------------------------------
	UPDATE_FIELD (state, userData) {
		Vue.set(state.userRegistration, userData.fieldName, userData.updatedValue);
	},

	UPDATE_FIELD_SELECTBOXES (state, selectBoxesData) {
		Vue.set(state.selectBoxes, selectBoxesData.fieldName, selectBoxesData.updatedValue);
	},

	UPDATE_FIELD_ERROR_MESSAGE (state, errorMessagesData) {
		Vue.set(state.errorMessages, errorMessagesData.fieldName, errorMessagesData.updatedValue);
	},
};

export const actions = {
	ageGateConfirmations ({ commit }, payload) {
		commit('AGE_GATE', payload);
	},

	timerPopupUpdate ({ commit }, { fieldName, updatedValue }) {
		commit('TIMER_POPUP', { fieldName, updatedValue });
	},

	popupQuestions ({ commit }, payload) {
		commit('POPUP_QUESTIONS', payload);
	},

	popupQuestionsIqosScience ({ commit }, payload) {
		commit('POPUP_QUESTIONS_IQOS_SCIENCE', payload);
	},

	popupQuestionsIqosScienceLater ({ commit }, payload) {
		commit('POPUP_QUESTIONS_IQOS_SCIENCE_LATER', payload);
	},

	popupQuestionsIqosThird ({ commit }, payload) {
		commit('POPUP_QUESTIONS_IQOS_THIRD', payload);
	},

	popupQuestionsIqosThirdLater ({ commit }, payload) {
		commit('POPUP_QUESTIONS_IQOS_THIRD_LATER', payload);
	},

	popupQuestionsProfil ({ commit }, payload) {
		commit('POPUP_QUESTIONS_PROFIL', payload);
	},

	updateField ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD', { fieldName, updatedValue });
	},

	updateFieldSelecboxes ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD_SELECTBOXES', { fieldName, updatedValue });
	},

	updateFieldErrorMessages ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD_ERROR_MESSAGE', { fieldName, updatedValue });
	},
};
